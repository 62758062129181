<template>
  <div>
    <v-card
      class="select-item"
      v-for="(item, index) in item"
      :key="index"
      @click="$emit('update', index)"
      :class="{ selected: index === value }"
    >
      <div class="icon">
        <div>
          <v-icon v-show="index === value" color="secondary" size="15">
            check
          </v-icon>
        </div>
      </div>
      <div
        class="label width-full d-flex align-center pl-4"
      >
        {{ item.label }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    item: {
      type: Array,
      default () {
        return []
      }
    },
    value: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang='sass' scoped>
.select-item
  display: flex
  width: 327px
  height: 50px
  font-weight: bold
  border-radius: 8px
  margin-bottom: 24px
  box-shadow: 0px 4px 8px 0px rgb(11 13 21 / 6%) !important
  overflow: hidden
  & .icon
    display: flex
    justify-content: center
    align-items: center
    width: 60px
    height: 100%
    background-color: rgba(199, 199, 199, 0.2)
    border-radius: 8px 0 0 8px !important
    & > div
      width: 24px
      height: 24px
      text-align: center
      background-color: #fff
  & .label
    width: 100%
    background-color: transparent
.selected
  color: #f5f5f5
  background-color: var(--theme_secondary)!important
  border-radius: 8px
  & .icon
    background-color: rgba(255, 255, 255, 0.2)
    border-radius: 8px 0 0 8px !important
</style>
