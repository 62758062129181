<template>
  <v-menu
    ref="menu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card class="d-flex elevation-0" color="#eeeeee" v-bind="attrs" v-on="on">
        <div class="label">{{ label }}</div>
        <v-spacer></v-spacer>
        <div>{{ value }}</div>
      </v-card>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>

<style lang='sass' scoped>
.label
  color: #9CA0AB !important

.value
  color: #000410 !important
</style>
