<template>
  <div class="width-500 d-flex flex-column justify-center align-center mx-auto">
    <div class="width-full f-s-20 f-w-600 mb-8">
      <span>Schedule New Payment</span>
    </div>

    <!-- 还款方式 -->
    <PaymentModeGroup
      :item="dataSources.original.item"
      v-model="dataSources.current.nowIndex"
    />

    <div class="select">
      <SelectStatic
        class="date"
        label="Next Payment Date"
        :value="dataSources.current.effectiveDate"
      >
        <v-date-picker
          width="350"
          v-model="date"
          :max="assists.tool.formatDateWithPattern(dataSources.current.lastPaymentDate, 'yyyy-mm-dd')"
          :min="assists.tool.formatDateWithPattern(dataSources.current.nextBusinessDay, 'yyyy-mm-dd')"
          :allowed-dates="(val) => getAllowedDates(val, 'no-weekend no-holiday start-now')"></v-date-picker>
      </SelectStatic>
      <v-divider v-show="dataSources.current.nowIndex !== 2" class="mx-2"></v-divider>
      <SelectStatic
        v-if="dataSources.current.nowIndex !== 2"
        label="Payment Frequency"
        :value="assists.dataType.PaymentFrequency[dataSources.current.payrollFrequencyIndex].text"
      >
        <v-list class="pa-0" width="350">
          <v-list-item-group
            mandatory
            color="var(--theme_primary)"
            v-model="dataSources.current.payrollFrequencyIndex"
          >
            <v-list-item
              v-for="(item, i) in assists.dataType.PaymentFrequency"
              :key="i"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </SelectStatic>
      <v-divider v-show="dataSources.current.nowIndex !== 2" class="mx-2"></v-divider>
      <SelectStatic
        v-if="dataSources.current.nowIndex === 0"
        label="Payments Count"
        :value="dataSources.current.paymentsCount"
      >
        <v-card width="350">
          <v-card-title class="py-2">Pay Off In N Payments</v-card-title>
          <v-card-text>
            <v-slider
              persistent-hint
              :hint="`Your loan will be paid off over the next ${dataSources.current.paymentsCount} payments.`"
              max="6"
              min="2"
              v-model="dataSources.current.paymentsCount"
            ></v-slider>
          </v-card-text>
        </v-card>
      </SelectStatic>
      <SelectStatic
        v-if="dataSources.current.nowIndex === 1"
        label="Payments Amount"
        :value="assists.tool.formatCurrency(dataSources.current.paymentsAmount)"
      >
        <v-card width="350">
          <v-card-title class="py-2">Pay A Fixed Amount</v-card-title>
          <v-card-text>
            <v-slider
              persistent-hint
              hint="You will have priority in repaying part of the principal."
              :max="maxPaymentsAmount"
              step="25"
              min="50"
              v-model="dataSources.current.paymentsAmount"
            ></v-slider>
          </v-card-text>
        </v-card>
      </SelectStatic>
    </div>

    <!-- 点击展开 -->
    <div class="width-350 mt-5">
      <a @click="onClickPreview"> Preview Payment Plan </a>
    </div>

    <div class="d-flex width-500 mt-10">
      <v-spacer></v-spacer>
      <web-btn
        class="mr-5"
        :height="50"
        :theme="'normal'"
        :width="140"
        @click="clickBack"
      >
        Back
      </web-btn>
      <web-btn
        :height="50"
        :width="140"
        @click="clickContinue"
      >Continue
      </web-btn>
    </div>

    <v-dialog
      v-model="layouts.dialog.paymentDetail" persistent
      width="700"
      class="preview-dialog"
      scrollable
    >
      <v-card>
        <v-card-actions>
          <v-card-title class="preview-dialog-title">Payment Plan</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon color="var(--theme_primary)" text @click="layouts.dialog.paymentDetail = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>

        <div class="amount-card elevation-0 px-6">
          <v-row no-gutters class="amount-table px-2">
            <template v-for="(item, index) in paymentFeeSetting">
              <v-col cols="6" class="field-label py-1" :key="index">{{ item.name }}</v-col>
              <v-col cols="6" class="text-right py-1" :key="item.name">{{ assists.currency.format(item.current) }}</v-col>
            </template>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="6" class="py-1 f-s-16 f-w-900">Total</v-col>
            <v-col cols="6" class="text-right py-1 f-s-16 f-w-900 primary-text">{{
                assists.currency.format(this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'total').current)
              }}
            </v-col>
          </v-row>
        </div>

        <v-card-text>
          <div class="installment-item" v-for="(item, index) in layouts.data.paymentDetails" :key="index">
            <div>
              <div class="date">{{ item.transactionDate }}</div>
              <div class="line" v-if="index !== (layouts.data.paymentDetails.length - 1)"></div>
            </div>
            <div class="right">
              <div>{{ assists.tool.formatCurrency(item.amount) }}</div>
              <v-btn text small>{{
                  assists.tool.getEnumTextByValue(assists.dataType.TransactionTypeEnum, item.transactionType).toUpperCase()
                }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { PaymentApi, InfrastructureApi } from '@/api'
import { Currency, DataType, Tool } from '@/assets/js/util'
import PaymentModeGroup from './childComps/PaymentModeGroup'
import SelectStatic from './childComps/SelectStatic'
import WebBtn from '@/components/base/WebBtn'

export default {
  components: {
    PaymentModeGroup,
    SelectStatic,
    WebBtn
  },
  data () {
    return {
      assists: {
        tool: new Tool(),
        dataType: DataType,
        currency: new Currency()
      },
      dataSources: {
        original: {
          item: [
            { label: 'Pay Off In N Payments' },
            { label: 'Pay A Fixed Amount Each Time' },
            { label: 'One Time Payment' }
          ]
        },
        current: {
          nowIndex: 0,
          effectiveDate: '', // 绑定用户选择的时间
          nextBusinessDay: '', // 用于限定最小的可选日期
          lastPaymentDate: '', // 用于限定最大的可选日期
          payrollFrequencyIndex: 0, // 工资频率的下标
          paymentsCount: 2,
          paymentsAmount: 50
        }
      },
      layouts: {
        data: {
          paymentDetails: []
        },
        dialog: {
          paymentDetail: false
        }
      }
    }
  },
  watch: {
    'dataSources.current.nowIndex': {
      handler () {
        this.paymentActions(DataType.PAYMENT_ACTION.PREVIEW.value)
      }
    }
  },
  async created () {
    await this.getEffectiveDate()
    await this.paymentActions(DataType.PAYMENT_ACTION.PREVIEW.value)
  },
  computed: {
    date: {
      get () {
        return this.assists.tool.formatDateWithPattern(
          this.dataSources.current.effectiveDate,
          'yyyy-mm-dd'
        )
      },
      set (val) {
        this.dataSources.current.effectiveDate =
          this.assists.tool.formatDateWithPattern(val, 'mm/dd/yyyy')
      }
    },
    paymentFeeSetting () {
      const init = [...this.$store.getters.getTransactionFeeList]
      const result = []
      init.map((item) => {
        if (item.fieldKey !== 'total') {
          result.push(item)
        }
      })
      return result
    },
    maxPaymentsAmount () {
      const max = this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'total')?.current
      return Math.floor(max / 25) * 25
    }
  },
  methods: {
    async getEffectiveDate () {
      await InfrastructureApi.getNextBusinessDay((res) => {
        this.dataSources.current.effectiveDate = res
        this.dataSources.current.nextBusinessDay = res
      })
      await InfrastructureApi.getFutureBusinessDay((res) => {
        this.dataSources.current.lastPaymentDate = res
      })
    },
    getAllowedDates (val, property) {
      let result = true
      if (property) {
        if (property.includes('end-now')) {
          result = result && val <= this.assists.tool.formatDate(new Date().getTime())
        }
        if (property.includes('start-now')) {
          result = result && val >= this.assists.tool.formatDate(new Date().getTime())
        }
        if (property.includes('no-weekend')) {
          const time = new Date(val)
          result = result && time.getUTCDay() !== 0 && time.getUTCDay() !== 6
        }
        if (property.includes('no-holiday')) {
          result = result && !this.store.getters.getHoliday.find((item) => item === val)
        }
      }

      return result
    },
    onClickPreview () {
      this.paymentActions(DataType.PAYMENT_ACTION.PREVIEW.value, true)
    },
    paymentActions (action, showPreview) {
      const nowIndex = this.dataSources.current.nowIndex

      if (this.assists.tool.isEmpty(this.dataSources.current.effectiveDate)) {
        return
      }

      const { PAY_OFF_IN_N, PAY_FIXED_AMOUNT, ONE_TIME_PAYMENT } =
        this.assists.dataType.PayOffEnum

      const payOffTypeEnum = [PAY_OFF_IN_N, PAY_FIXED_AMOUNT, ONE_TIME_PAYMENT]
      const payOffType = payOffTypeEnum[nowIndex].value

      const paymentApiMethods = [
        PaymentApi.payOffInN,
        PaymentApi.payFixedAmount,
        PaymentApi.oneTimePayment
      ]
      paymentApiMethods[nowIndex](
        {
          loanNo: this.$store.getters.getCollectionLoanNo,
          changeType: payOffType,
          action: action,
          couponCode: this.$store.getters.getCouponCode,
          effectiveDate: this.dataSources.current.effectiveDate,
          debitCount: nowIndex === 0 ? this.dataSources.current.paymentsCount : null,
          regularAmount: nowIndex === 1 ? this.dataSources.current.paymentsAmount : null,
          currentInterest: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'financeFee')?.current || 0,
          currentExtraInterest: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'unpaidFee')?.current || 0,
          currentPrincipal: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'principal')?.current || 0,
          currentLateFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'lateFee')?.current || 0,
          currentNsfFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'nsfFee')?.current || 0,
          currentBankFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'bankFee')?.current || 0,
          currentRefuseFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'refuseFee')?.current || 0,
          currentTotal: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'total')?.current || 0,
          payrollFrequency: nowIndex === 2 ? null : DataType.PaymentFrequency[this.dataSources.current.payrollFrequencyIndex].value
        },
        (res) => {
          this.layouts.data.paymentDetails = Array.isArray(res)
            ? [...res]
            : [res]
          if (action === DataType.PAYMENT_ACTION.SAVE.value) this.$router.push('/notification/103')
          // preview之前先调接口，返回成功才展示详细信息的dialog
          if (showPreview) this.layouts.dialog.paymentDetail = true
        },
        (err) => this.assists.tool.setPopupInformation(err.message)
      )
    },
    clickBack () {
      this.$router.push('/coupon/overview')
    },
    clickContinue () {
      this.paymentActions(DataType.PAYMENT_ACTION.SAVE.value)
    }
  }
}
</script>

<style lang='sass' scoped>
.select
  width: 350px
  background-color: #eeeeee
  border-radius: 8px

  & :deep(> div)
    height: 42px !important
    line-height: 42px
    padding: 0 16px

.amount-card
  width: 100%
  border-radius: 8px
  margin-bottom: 20px

  & .amount-table
    padding: 8px 0
    background-color: #ededee
    border-radius: 10px

    & th
      height: 30px
      text-align: right !important
      font-weight: 500
      color: black !important
      font-size: 10px

    & td
      height: 24px
      text-align: right
      font-size: 12px
      font-weight: 500
      border: none !important

    & .name-td
      font-weight: 500

    & .text-grey
      text-align: left
      color: #9CA0AB

    & .text-total
      border-top: 1px solid rgba(0, 0, 0, 0.12) !important
      text-align: left
      font-size: 15px

    & .value-total
      border-top: 1px solid rgba(0, 0, 0, 0.12) !important
      color: var(--theme_primary)
      font-size: 15px

.installment-item
  display: flex
  justify-content: space-between

  & .date
    color: #9ba1b7
    font-size: 13px
    margin-left: 20px
    margin-top: 12px
    margin-bottom: 10px

  & .line
    width: 100%
    height: 35px
    margin-top: 5px
    border-left: 1px solid #9ba1b7 !important
    transform: translate(60%)

  & .right
    display: flex
    align-items: center
    height: 50px
    padding: 0 20px
    margin-left: 16px
    border: 1px solid #eef1f8
    border-radius: 4px 12px 12px 12px

    & :deep(.v-btn)
      margin-left: 50px

// date-picker的选择颜色
:deep(.v-btn--active)
  background-color: var(--theme_primary) !important

:deep(.accent--text)
  color: var(--theme_primary) !important
</style>
