import { render, staticRenderFns } from "./SelectStatic.vue?vue&type=template&id=f0ed407c&scoped=true"
import script from "./SelectStatic.vue?vue&type=script&lang=js"
export * from "./SelectStatic.vue?vue&type=script&lang=js"
import style0 from "./SelectStatic.vue?vue&type=style&index=0&id=f0ed407c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0ed407c",
  null
  
)

export default component.exports